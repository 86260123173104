import dynamic from 'next/dynamic';

import { checkAddContent } from 'lib/features/createOrderV2/helpers';
import { getTableDateDays } from 'utils';
import { GetFieldsProps } from './types';
import classes from '../Content.module.scss';

const AdderBtnOffer = dynamic(() => import('../../../AdderBtn/AdderBtnOffer/AdderBtnOffer'), { ssr: false });

export const getFields = ({
  loading, field, quickDeploy, extendedFormContent, onDeleteContent,
}: GetFieldsProps) => [
  {
    id: 'id',
    cell: (row) => {
      const { id } = row || {};
      return id;
    },
  },
  {
    id: 'name',
    cell: (row) => {
      const { offerInfo } = row || {};
      const { name } = offerInfo || {};
      return name;
    },
  },
  {
    id: 'provider',
    cell: (row) => {
      const { providerInfo } = row || {};
      const { name } = providerInfo || {};
      return name;
    },
  },
  {
    id: 'updated',
    cell: (row) => {
      const { modifiedDate } = row?.origins || {};
      return modifiedDate ? getTableDateDays(modifiedDate) : '-';
    },
  },
  {
    id: 'category',
    cell: (row) => {
      const { models } = row?.configuration?.attributes || {};
      const { pipelineType } = models?.[0]?.task || {};
      return pipelineType;
    },
  },
  {
    id: 'isVerified',
    cell: (row) => {
      const { verifiedAt } = row || {};
      return !!verifiedAt;
    },
  },
  {
    id: 'button',
    cell: (row) => {
      const { id, enabled, inactive } = row || {};
      const isAdded = checkAddContent({ offerId: id, formContent: extendedFormContent, field });
      return (
        <AdderBtnOffer
          disabled={loading}
          onDeploy={() => quickDeploy({ offerId: id, data: row, field })}
          isAdded={isAdded}
          className={classes.adderBtn}
          onDelete={() => onDeleteContent({ offerId: id, field })}
          dataTestId={`offer-${id}`}
        />
      );
    },
  },
  {
    id: 'disabled',
    cell: (row) => {
      const { id, enabled } = row || {};
      // return isOfferDisabled(id, enabled);
    },
  },
];
