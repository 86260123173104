import { ReactNode } from 'react';

import { Notifications } from 'lib/features/alerts/types';
import { Mode } from 'uikit/NotificationLine/types';

export enum Views {
  mobile = 'mobile',
  desktop = 'desktop'
}

export interface ListAlert {
  id: Notifications;
  text: ReactNode | string;
  view: Views[];
  mode?: Mode;
}