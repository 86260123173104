import {
  memo, useState, useCallback, SyntheticEvent, useMemo,
} from 'react';
import { useField } from 'formik';
import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { Icon } from 'uikitv2/Icon';
import { Checkbox } from '../Checkbox';
import { CheckboxGroupAccordionProps } from './types';
import classes from './CheckboxGroupAccordion.module.scss';

export const CheckboxGroupAccordion = memo(({
  name, list, onChange, onChangeOpen = () => {}, textBtn, limit = 0,
}: CheckboxGroupAccordionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, { value, error }, { setValue }] = useField(name);
  const onClick = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => {
      onChangeOpen(!prev);
      return !prev;
    });
  }, [onChangeOpen]);

  const height = useMemo(() => {
    const LINE_HEIGHT = 19;
    const ROW_GAP = 16;
    return (
      isOpen
        ? LINE_HEIGHT * (list?.length || 0) + ROW_GAP * ((list?.length || 0) - 1)
        : LINE_HEIGHT * limit + ROW_GAP * (limit - 1)
    );
  }, [isOpen, limit, list.length]);

  return (
    <Box direction="column">
      <div
        className={cn(classes.content, { [classes.open]: isOpen })}
        style={{ height: limit ? `${height}px` : 'auto' }}
      >
        {list.map((item, idx) => (
          <Checkbox
            error={error}
            checked={(value || []).find((el: string) => el === item.value) !== undefined}
            onChange={(val) => {
              if (val) {
                setValue([...value, item.value]);
              } else {
                setValue(value.filter((el: string) => el !== item.value));
              }
              onChange?.();
            }}
            key={item.value}
            name={`${name}-${idx}`}
            label={item.label}
          />
        ))}
      </div>
      {!!limit && limit < (list?.length || 0) && (
        <Box className={classes['wrap-btn']} justifyContent="flex-start">
          <Box
            className={cn(classes.btn, { [classes.openbtn]: isOpen })}
            onClick={onClick}
            alignItems="center"
          >
            <span className={classes.text}>{textBtn}</span>
            <Icon name="triangle2" className={cn(classes.icon, { [classes.openicon]: isOpen })} />
          </Box>
        </Box>
      )}
    </Box>
  );
});
