import { memo, useCallback, useState } from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { Button } from 'uikitv2/Buttons/Button';
import { InputFormik } from 'uikitv2/Input/InputFormik';
import { Icon } from 'uikitv2/Icon';
import { sleep } from 'utils';
import { defaultPlaceholder } from './helpers';
import classes from './Search.module.scss';
import { SearchProps } from './types';

const interval = 600;

export const Search = memo(({
  name, onSubmit, className,
}: SearchProps) => {
  const [, { value }, { setValue }] = useField(name);
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder);

  const debouncedSubmit = useDebouncedCallback(onSubmit, interval);

  const onChange = useCallback(() => {
    debouncedSubmit();
  }, [debouncedSubmit]);

  const onFocus = useCallback(() => {
    setPlaceholder('');
  }, []);

  const onBlur = useCallback(() => {
    setPlaceholder(defaultPlaceholder);
  }, []);

  const prepend = useCallback(
    ({ focused }) => (
      <Icon
        name="search"
        fill="none"
        className={cn(classes.icon, { [classes['icon-focused']]: focused })}
      />
    ),
    [],
  );

  const onSubmitDeferred = useCallback(async () => {
    await sleep(1);
    onSubmit();
  }, [onSubmit]);

  const append = useCallback(
    () => (
      <Button
        onClick={async () => {
          setValue(undefined);
          onSubmitDeferred();
        }}
        className={classes.btn}
        variant="obscure2"
      >
        <Icon
          name="cross_mini"
          fill="none"
        />
      </Button>
    ),
    [setValue, onSubmitDeferred],
  );

  const onKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      onSubmit?.();
    }
  }, [onSubmit]);

  return (
    <InputFormik
      id="control-panel-search"
      prepend={prepend}
      append={value ? append : undefined}
      classNameWrap={cn(classes['input-wrap'], className)}
      classNameInput={classes.input}
      classNamePrepend={classes['input-prepend']}
      classNameAppend={classes['input-append']}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      onKeyDown={onKeyDown}
    />
  );
});
