import React, {
  memo,
  useRef,
  FC,
} from 'react';
import cn from 'classnames';

import { useIntersectionObserver } from 'uikitv2/common/hooks/useIntersectionObserver';
import { EmptyPlace } from 'uikitv2/EmptyPlace';
import { LazyLoadProps } from './types';
import classes from './LazyLoad.module.scss';

export const LazyLoad: FC<LazyLoadProps> = memo(({
  hasNextPage,
  fetchNextPage,
  loading,
  showLoader = false,
  children,
  className,
  testId,
}) => {
  const triggerRef = useRef(null);
  useIntersectionObserver(
    triggerRef,
    {},
    () => hasNextPage && !loading && fetchNextPage?.(),
  );

  return (
    <div className={cn(classes.wrap, className)} data-testid={testId}>
      {children}
      {showLoader && loading && (
        <EmptyPlace
          spinnerWrapClassName={classes['spinner-wrap']}
        />
      )}
      <div ref={triggerRef} className={cn(classes.trigger)} />
    </div>
  );
});
