import { memo, useCallback } from 'react';
import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import { isMobileSelector } from 'lib/features/windowSize';
import { TotalCounter } from 'common/components/TotalCounter';
import { TableConfigurator } from 'components/TableConfigurator/TableConfigurator';
import { viewTypeSelector } from 'lib/features/shopwindow/selectors';
import { ControlPanelFields, FiltersFields, ViewType } from 'lib/features/shopwindow/types';
import { updateViewType } from 'lib/features/shopwindow';
import classes from './ControlPanel.module.scss';
import { Sorting } from './Sorting';
import { Switcher } from './Switcher';
import { Search } from './Search';
import { ControlPanelProps } from './types';

export const ControlPanel = memo(({
  columns, name, active, onSubmit, showCount, interval = 100, filtersName, totalCount,
}: ControlPanelProps) => {
  const isMobile = useAppSelector(isMobileSelector);
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(viewTypeSelector(active));
  const onClick = useCallback(() => {
    dispatch(updateViewType({ viewType: viewType === ViewType.table ? ViewType.cards : ViewType.table, page: active }));
  }, [active, dispatch, viewType]);

  return (
    <Box className={classes.wrap} alignItems="center" justifyContent="space-between">
      <TotalCounter showCount={totalCount} label={showCount === 1 ? 'model' : 'models'} />
      <Box alignItems="center">
        <Search {...{
          name: `${filtersName}.${FiltersFields.search}`,
          onSubmit,
          className: viewType === ViewType.table ? classes.mr197 : classes.mr151,
        }}
        />
        <Box>
          <Sorting
            className={classes.mr}
            name={`${name}.${ControlPanelFields.sorting}`}
            onSubmit={onSubmit}
            interval={interval}
            page={active}
          />
          {!!viewType && <Switcher className={cn(classes.switcher, classes.mr)} onClick={onClick} viewType={viewType} />}
          {viewType === ViewType.table && !isMobile && (
            <TableConfigurator
              className={classes.mr}
              classNameDropdownToggle={classes['dropdown-toggle']}
              classNameDropdownMenu={classes['dropdown-menu']}
              columns={columns}
              table={active}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
});