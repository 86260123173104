import { memo, useMemo } from 'react';
import { useField } from 'formik';

import { Radio } from 'uikitv2/Radio';
import { RadioAdvanced  } from 'uikitv2/Radio/RadioAdvanced';
import { isChecked } from './helpers';
import { RadioFormikProps } from './types';

export const RadioFormik = memo(({
  name,
  value,
  onChange = () => {},
  append,
  ...rest
}: RadioFormikProps) => {
  const [, meta, { setValue }] = useField(name);
  const { value: valueFormik } = meta;
  const Component = useMemo(() => append ? RadioAdvanced : Radio, [append]);

  return (
    <Component
      {...meta}
      {...rest}
      checked={isChecked(value, valueFormik)}
      onChange={(event) => {
        setValue((event.target as HTMLInputElement).value);
        onChange(event);
      }}
      {...{ value, name, append }}
    />
  );
});
