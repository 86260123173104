import { memo, useMemo } from 'react';

import { Icon } from 'uikitv2/Icon';
import { Button } from 'uikitv2/Buttons/Button';
import { SwitcherProps } from './types';
import { getSwitcherSettings } from './helpers';

export const Switcher = memo<SwitcherProps>(({ className, onClick, viewType }) => {
  const switcherSettings = useMemo(() => getSwitcherSettings(viewType), [viewType]);
  return (
    <Button
      variant="obscure"
      className={className}
      onClick={onClick}
      data-testid="button-switchmode"
    >
      <Icon {...switcherSettings} />
    </Button>
  );
});

export default Switcher;
