import { memo } from 'react';

import { RadioFormik } from 'uikitv2/Radio/RadioFormik';
import { FiltersWrapper } from '../FiltersWrapper';
import { VerifiedProps } from './types';
import classes from './Verified.module.scss';

export const Verified = memo(({
  name, title, onChange, list
}: VerifiedProps) => {
  return (
    <FiltersWrapper title={title}>
      {(list || []).map(({ value, label, ext }) => (
        <RadioFormik
          key={value}
          name={name}
          value={value}
          label={label}
          onChange={onChange}
          append={<span className={classes.ext}>{ext}</span>}
        />
      ))}
    </FiltersWrapper>
  );
});
