import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { Spinner } from 'uikitv2/Spinner';
import { EmptyPlaceProps } from './types';
import classes from './EmptyPlace.module.scss';

export const EmptyPlace = ({ spinnerTextClassName, spinnerWrapClassName, text }: EmptyPlaceProps) => {
  return (
    <Box alignItems="center" justifyContent="center" className={spinnerWrapClassName}>
      {text || (
        <>
          <Spinner />
          <span className={cn(classes['spinner-text'], spinnerTextClassName)}>loading...</span>
        </>
      )}
    </Box>
  );
};
