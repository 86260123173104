import { OffersModerationQuery } from 'generated/schemas/offers';
import { Verified } from 'lib/features/shopwindow/types';

export const getList = (data?: OffersModerationQuery) => {
  if (!data) {
    return [];
  }
  
  const { unverified = 0, verified = 0 } = data.result;

  return [
    {
      value: Verified.all,
      label: 'All',
      ext: unverified + verified,
    },{
      value: Verified.verified,
      label: 'Verified',
      ext: verified,
    }, {
      value: Verified.unsorted,
      label: 'Unsorted',
      ext: unverified,
    },
  ];
};
