import cn from 'classnames';

import { Box } from 'uikitv2/Box';
import { Card2 } from 'uikitv2/Card2';
import { VerifiedTooltip } from 'common/components/VerifiedTooltip';
import { Tooltip } from 'uikitv2/Tooltip';
import { CardTemplateDataProps } from './types';
import classes from '../CardTemplate.module.scss';

export const CardTemplateData = ({
  card, onClick, testId,
}: CardTemplateDataProps) => (
  <Card2 {...{ onClick, testId }}>
    <Box className={cn(classes.card, { [classes.disabled]: card.disabled })} direction="column" justifyContent="space-between">
      <Box direction="column">
        <div className={classes.groupLine}>
          <Box alignItems="center" className={classes['title-line']}>
            <div className={classes['title-name']}>{card.name}</div>
            {card.isVerified && <VerifiedTooltip />}
          </Box>
          {card.button}
        </div>
        <Box alignItems="center" justifyContent="space-between" className={classes.group}>
          <div>
            <span className={classes.title}>Provider:</span>
            <Tooltip
              className={classes.tooltip}
              tooltip={card.provider?.length > 30 ? card.provider : undefined}
            >
              <div className={classes.text}>{card.provider}</div>
            </Tooltip>
          </div>
          <div>
            <span className={classes.title}>Updated:</span>
            <span className={classes.text}>{card.updated}</span>
          </div>
        </Box>
      </Box>
      {card.category && (
        <Box justifyContent="space-between" className={classes['chips-group']}>
          <div className={classes.chips}>
            {card.category}
          </div>
        </Box>
      )}
    </Box>
  </Card2>
);
