import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { Box } from 'uikitv2/Box';
import { getInitialFilters } from 'lib/features/shopwindow/helpers';
import { FiltersProps } from './types';
import { controls } from './helpers';
import classes from './Filters.module.scss';

export const Filters = memo(({
  className, name, onSubmit, interval = 100, active,
}: FiltersProps) => {
  const submitDebounced = useDebouncedCallback(onSubmit, interval);
  const [,, { setValue }] = useField(name);
  const Control = useMemo(() => controls[active], [active]);
  const onClickResetFilters = useCallback(() => {
    setValue(getInitialFilters(active));
    submitDebounced();
  }, [submitDebounced, setValue, active]);

  return (
    <Box className={cn(classes.wrap, className)} direction="column">
      <Box onClick={onClickResetFilters} className={classes['reset-filters']}>
        Reset filters
      </Box>
      {!!Control && (
        <Control {...{ name, onSubmit: submitDebounced }} />
      )}
    </Box>
  );
});
