import { memo } from 'react';

import { Box } from 'uikitv2/Box/Box';
import { Radio } from './Radio';
import { RadioAdvancedProps } from './types';
import classes from './Radio.module.scss';

export const RadioAdvanced = memo<RadioAdvancedProps>(({ append, ...props }) => {
  return (
    <Box className={classes.advanced}>
      <Radio
        {...props}
      />
      {append}
    </Box>
  );
});
