import { memo, useMemo } from 'react';
import { useField } from 'formik';

import { useOffersModerationQuery } from 'generated/schemas/offers';
import { getPreparedFiltersModels } from 'lib/features/shopwindow/helpers';
import { Verified as VerifiedCommon  } from '../../common/Verified';
import { VerifiedProps } from './types';
import { getList } from './helpers';

export const Verified = memo<VerifiedProps>(({ rootName, ...props }) => {
  const [, { value }] = useField(rootName);
  const { data } = useOffersModerationQuery({ filter: getPreparedFiltersModels({ ...value, verified: undefined }) });
  const list = useMemo(() => getList(data), [data]);
  
  return (
    <VerifiedCommon
      {...{
        list,
        ...props
      }}
    />
  );
});
