import { useCallback, SyntheticEvent } from 'react';

import { useAppSelector } from 'lib/hooks';
import { alertsAlertsSelector } from 'lib/features/alerts';
import { Notifications } from 'lib/features/alerts/types';
import { useNotificationsLineAllerts } from './useNotificationsLineAllerts';
import { listAlerts } from './helpers';

export const useNotificationsLine = () => {
  const alerts = useAppSelector(alertsAlertsSelector);
  const { onClose: onCloseAlert } = useNotificationsLineAllerts(listAlerts);

  const onClose = useCallback((e: SyntheticEvent, id: Notifications) => {
    e.stopPropagation();
    return onCloseAlert(id);
  }, [onCloseAlert]);

  return {
    alerts,
    onClose,
  };
};
