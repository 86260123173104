import { memo } from 'react';

import { Box } from 'uikitv2/Box';
import { TotalCounterProps } from './types';
import classes from './TotalCounter.module.scss';

export const TotalCounter = memo(({ showCount, label }: TotalCounterProps) => {
  return (
    <Box>
      <span className={classes.showing}>Found:</span>
      <span data-testid="span-counter">{showCount || 0} {label}</span>
    </Box>
  );
});
