import { memo, useMemo } from 'react';

import { useGetUsedPipelineTypesQuery } from 'generated/schemas/getUsedPipelineTypes';
import { getInitialFiltersModels, getPreparedFiltersModels } from 'lib/features/shopwindow/helpers';
import { Categories as CategoriesCommon } from '../../common/Categories';
import { getCategories } from '../../common/Categories/helpers';
import { CategoriesProps } from './types';

export const Categories = memo<CategoriesProps>((props) => {
  const { data } = useGetUsedPipelineTypesQuery({ filter: getPreparedFiltersModels(getInitialFiltersModels()) });
  const list = useMemo(() => getCategories((data?.result || [])), [data]);
  return <CategoriesCommon {...props} list={list} />;
});
