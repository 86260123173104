import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import dynamic from 'next/dynamic';

import {
  OfferInfo, ProviderInfo, Offer, PriceType,
} from 'generated/types';
import { TooltipDeposit } from 'components/TooltipDeposit';
import { Box } from 'uikitv2/Box';
import { checkAddContent, getSlotPrice } from 'lib/features/createOrderV2/helpers';
import { TOKEN_NAME } from 'common/constants';
import { Column } from '../Table/types';
import { GetColumnsProps } from './types';

const columnHelper = createColumnHelper<Column>();
const AdderBtnOffer = dynamic(() => import('../../../AdderBtn/AdderBtnOffer/AdderBtnOffer'), { ssr: false });

export const getColumns = ({
  extendedFormContent, loading, onDeleteContent, field, quickDeploy,
}: GetColumnsProps): ColumnDef<Column>[] => [
  columnHelper.accessor('id', {
    header: 'ID',
    id: 'id',
    size: 82,
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('offerInfo', {
    id: 'name',
    header: 'Name',
    size: 180,
    cell: (info) => {
      const { name } = info.getValue() as OfferInfo || {};
      return name;
    },
  }),
  columnHelper.accessor('providerInfo', {
    id: 'provider',
    header: 'Provider',
    size: 150,
    cell: (info) => {
      const { name } = info.getValue() as ProviderInfo || {};
      return name;
    },
  }),
  columnHelper.accessor('slots', {
    id: 'priceHr',
    header: 'Price, hr',
    cell: (info) => {
      const original = info.row.original as Offer || {};
      const price = getSlotPrice(original, PriceType.PerHour, extendedFormContent, field);
      return price
        ? (
          <TooltipDeposit deposit={price} currency={TOKEN_NAME} tooltip={false} />
        ) : '-';
    },
  }),
  columnHelper.accessor('slots', {
    id: 'priceFixed',
    header: 'Price, fix',
    cell: (info) => {
      const original = info.row.original as Offer || {};
      const price = getSlotPrice(original, PriceType.Fixed, extendedFormContent, field);
      return price
        ? (
          <TooltipDeposit deposit={price} currency={TOKEN_NAME} tooltip={false} />
        ) : '-';
    },
  }),
  columnHelper.accessor('offerInfo', {
    header: '',
    id: 'icon',
    meta: {
      show: true,
    },
    size: 100,
    cell: (info) => {
      const original = info.row.original as Offer || {};
      const { id } = original;
      const isAdded = checkAddContent({ offerId: id, formContent: extendedFormContent, field });
      return (
        <Box justifyContent="flex-end">
          <AdderBtnOffer
            disabled={loading}
            onDeploy={() => quickDeploy({ offerId: id, data: original, field })}
            isAdded={isAdded}
            onDelete={() => onDeleteContent({ offerId: id, field })}
            dataTestId={`offer-${id}`}
          />
        </Box>
      );
    },
  }),
];
