import { memo, useCallback } from 'react';

import { FiltersFields } from 'lib/features/shopwindow/types';
// import { Offers } from '../common/Offers';
// import { Compatible } from '../common/Compatible';
import { Box } from 'uikitv2/Box';
import { FiltersModelsProps } from './types';
import { Categories } from './Categories';
import { Verified } from './Verified';
import classes from './FiltersModels.module.scss';

export const FiltersModels = memo(({
  name, onSubmit: onSubmitProps,
}: FiltersModelsProps) => {
  const onSubmit = useCallback(() => onSubmitProps(), [onSubmitProps]);

  return (
    <Box direction="column" className={classes.group}>
      {/* <Compatible name={`${name}.${FiltersFields.compatible}`} title={FiltersFields.compatible} onChange={onSubmit} /> */}
      <Verified
        rootName={name}
        name={`${name}.${FiltersFields.verified}`}
        title="Offers"
        onChange={onSubmit}
      />
      <Categories
        name={`${name}.${FiltersFields.categories}`}
        title={FiltersFields.categories}
        onChange={onSubmit}
      />
      {/* <Offers name={`${name}.${FiltersFields.offers}`} title="Offers" onChange={onSubmit} canShowBorder={false} /> */}
    </Box>
  );
});