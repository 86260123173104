import { memo, useState, useCallback } from 'react';

import { CheckboxGroupAccordion } from 'uikitv2/Checkbox/CheckboxGroupAccordion';
import { CategoriesProps } from './types';
import { FiltersWrapper } from '../FiltersWrapper';

export const Categories = memo(({
  name, title, list, onChange,
}: CategoriesProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onChangeOpen = useCallback((val) => {
    setIsOpen(val);
  }, []);

  return (
    <FiltersWrapper title={title}>
      <CheckboxGroupAccordion
        name={name}
        list={list}
        onChange={onChange}
        textBtn={isOpen ? 'Collapse' : 'Show all categories'}
        limit={6}
        onChangeOpen={onChangeOpen}
      />
    </FiltersWrapper>
  );
});
