/* eslint-disable max-len */
// import Link from 'next/link';
// import { DocumentationChapter, documentation } from 'common/links';
import { Notifications } from 'lib/features/alerts/types';
import {
  Views,
  ListAlert,
} from './types';

// import classes from '../NotificationsLine.module.scss';

export const listAlerts: ListAlert[] = [
  {
    id: Notifications.MARCH_MIGRATE_BNB,
    text: 'March 10, 2025. As part of our migration strategy, we have successfully transitioned to the opBNB blockchain. Transactional token is now BNB, Super Protocol test token is SPPI. All orders have been reset. For Web2 users, the transition should be seamless. For Web3 users, if the MetaMask extension does not automatically detect the opBNB blockchain, please restart your browser.',
    //   text:
    // <p className={classes.paragraph}>March 10, 2025. As a part of our migration strategy, we have successfully adopted the opBNB blockchain.&nbsp;
    //   <Link
    //     href={documentation[DocumentationChapter['token-limits']]}
    //     target="_blank"
    //     className={classes.link}
    //   >
    //     Learn more
    //   </Link>&nbsp;
    //   Transactional token is now BNB, Super Protocol test token is SPPI. All orders have been reset.
    // </p>,
    view: [Views.desktop],
  },
  // {
  //   id: Notifications.MIGRATED,
  //   text: 'Super Protocol has successfully migrated to the Polygon mainnet! You can now request test tokens and create orders. Since this is a different blockchain, all previous orders have been reset. For Web3 users, Metamask should automatically switch to the Polygon mainnet when connecting to the Marketplace. If it doesn’t, please update it manually.',
  //   view: [Views.desktop],
  // },
  // {
  //   id: Notifications.FEBRUARY_LOST_ORDERS,
  //   text: 'Feb 7, 2025. AI Marketplace is about to commence the move from Polygon testnet to the mainnet blockchain, which will be used going forward. Because it’s a different blockchain, all order history will be lost and active orders will be canceled. This is a major change and will require longer downtime: from Feb 11th until Feb 14th.',
  //   view: [Views.desktop],
  // },
  // {
  //   id: Notifications.NODE_CRASH,
  //   text: 'The Polygon testnet node went through a hardfork and is now offline. We will need to assign a new node. Order creation and management will be unavailable until this warning disappears.',
  //   view: [Views.desktop],
  // },
  // {
  //   id: Notifications.USE_DESKTOP,
  //   text: 'Mobile version of Marketplace is view-only. Please use desktop for full functionality.',
  //   view: [Views.desktop],
  // },
];
